import React, { useEffect, useState } from 'react';
import { Avatar, CaretIcon, Navigation, SignOutIcon } from '@myob/myob-widgets';
import { auth, telemetry } from '@my-account/tools';
import { UserProfileWrapper } from './styles';
import { UserProfileType } from 'helpers/types';
import { fetchUserProfile } from 'helpers/api';
import Cookies from 'js-cookie';
import { settingTree } from './userProfileConfigs';

type UserProfileProps = {
  isAccountView: boolean;
  isMobile?: boolean;
  setFullName?: (fullName: string) => void;
};

export const defaultName = 'User profile';

export const UserProfile: React.FC<UserProfileProps> = ({ isAccountView, isMobile, setFullName }) => {
  const [userProfile, setUserProfile] = useState<UserProfileType | Record<string, never>>({});
  const getUserProfile = () => {
    fetchUserProfile()
      .then((res) => {
        setUserProfile({ ...res });
        const userProfile = { firstName: res.firstName, lastName: res.lastName, email: res.email };
        localStorage.setItem('USER_PROFILE', JSON.stringify(userProfile));

        const user = auth.getUser();
        const myobVisitorID = Cookies.get('myob_visitor_id');
        user &&
          telemetry.identify(user.userId, {
            firstName: res.firstName,
            lastName: res.lastName,
            emailAddress: res.email,
            visitorId: myobVisitorID,
            userId: user.userId,
          });
      })
      .catch(() => {
        const emptyUserProfile = { firstName: '', lastName: '' };
        setUserProfile(emptyUserProfile);
        localStorage.setItem('USER_PROFILE', JSON.stringify(emptyUserProfile));
        return;
      });
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  useEffect(() => {
    const handleStorage = () => {
      const data = JSON.parse(localStorage.getItem('USER_PROFILE'));
      setUserProfile({ firstName: data.firstName, lastName: data.lastName });
    };

    window.addEventListener('localstorage_user_profile_change', handleStorage);
    return () => window.removeEventListener('localstorage_user_profile_change', handleStorage);
  }, []);

  const generateSettingTree = () => {
    return settingTree(isAccountView).map((settingItem) => {
      return (
        <div key={settingItem.key} onKeyDown={settingItem.onKeyDown} tabIndex={0} role="button">
          <Navigation.MenuLink
            key={settingItem.key}
            label={settingItem.label}
            url={settingItem.url}
            onClick={settingItem.onClick}
            icon={settingItem.icon != null ? <settingItem.icon /> : null}
          />
        </div>
      );
    });
  };

  const { firstName = '', lastName = '' } = userProfile;
  const fullName = `${firstName} ${lastName}`.trim();
  const avatarDisplayName = fullName ? fullName : 'Anonymous';

  if (isMobile) {
    setFullName(fullName);
  }

  return (
    !isMobile && (
      <UserProfileWrapper>
        <Navigation.Menu
          className="user-profile-container"
          key="userProfile"
          label={
            <>
              <Avatar type="user" color="light-grey" name={avatarDisplayName} />
              <span className="user-profile-text">{fullName ? fullName : defaultName}</span>
            </>
          }
          items={generateSettingTree()}
          icon={<CaretIcon />}
        />
      </UserProfileWrapper>
    )
  );
};
